import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import FAQPage from '../templates/FAQ';
import questions from '../../content/questions/questions';

const faq = {
  title: 'faqTitle',
  subTitle: 'faqSubTitle',
  findText: 'faqFindText',
  generalText: 'faqGeneral',
  technicalText: 'faqTechnical',
  questions
};

const FAQ = (props) => (
  <Layout {...props}>
    <FAQPage
      data={faq}
    />
  </Layout>
);


export default withIntl(FAQ);

