import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, TextField } from 'mw-style-react';
import cn from 'classnames';
import './FAQ.scss';
import Link from '../../i18n/Link';
import Form from '../../components/Form/Form';
import Utils from '../../utils/utils';

const host = process.env.GATSBY_HOST;

const form = {
  title: 'formLiveDemoContactUs',
  type: 'liveDemo',
  fields: [
    {
      type: 'firstName',
      label: 'formFirstName',
      value: ''
    },
    {
      type: 'lastName',
      label: 'formLastName',
      value: ''
    },
    {
      type: 'email',
      label: 'formEmail',
      value: ''
    },
    {
      type: 'company',
      label: 'formCompany',
      value: ''
    },
    {
      type: 'chanel',
      label: 'formChanel',
      value: '',
      list: [
        {
          value: 'Zoom'
        },
        {
          value: 'Skype',
        },
        {
          value: 'formChanelOther'
        }
      ]
    },
    {
      type: 'date',
      label: 'formDate',
      value: ''
    },
    {
      type: 'message',
      label: 'formMessage',
      value: ''
    },
  ],
  button: {
    text: 'formSend',
    link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
  }
};


class FAQPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      type: 'general',
      oldType: 'general',
      findText: '',
      showQuestions: true
    };
  }

  // _handleScroll() {
  //   this.addHypercomments();
  // }
  //
  // addHypercomments() {
  //   if (typeof window !== 'undefined') {
  //     const script = `_hcwp = window._hcwp || [];
  //       _hcwp.push({widget:"Stream", widget_id: 106047});
  //       (function() {
  //       if("HC_LOAD_INIT" in window)return;
  //       HC_LOAD_INIT = true;
  //       var lang = (navigator.language || navigator.systemLanguage ||
  //          navigator.userLanguage ||  "en").substr(0, 2).toLowerCase();
  //       var hcc = document.createElement("script");
  //          hcc.type = "text/javascript"; hcc.async = true;
  //       hcc.src = ("https:" == document.location.protocol ? "https" : "http")+"://w.hypercomments.com/widget/hc/106047/"+lang+"/widget.js";
  //       var s = document.getElementsByTagName("script")[0];
  //       s.parentNode.insertBefore(hcc, s.nextSibling);
  //       })();`;
  //     // Utils.addTag('script', {
  //     //   innerText: script
  //     // });
  //     setTimeout(() => {
  //       const s = document.createElement('script');
  //       s.type = 'text/javascript';
  //       s.innerText = script;
  //       console.log('s', s);
  //       document.getElementsByTagName('body')[0].appendChild(s);
  //     }, 100);
  //   }
  // }

  renderItem(items) {
    const { type, findText, oldType } = this.state;
    const renderType = type === oldType ? type : oldType;
    let renderItems = items[renderType];
    if (findText) {
      renderItems = items[renderType].filter(item => {
        const question = item.question.toLowerCase();
        const answer = item.answer.toLowerCase();
        const ft = findText.toLowerCase();
        return question.indexOf(ft) !== -1 || answer.indexOf(ft) !== -1;
      });
    }

    if (renderItems.length > 0) {
      return renderItems.map((item, index) => (
        <div
          className="faq__item"
          key={index}
        >
          <div className="faq__item__title">{item.question}</div>
          <span
            className="faq__item__text"
            dangerouslySetInnerHTML={{
              __html: Utils.bbCodeToHtml(item.answer)
            }}
          />
        </div>
      ));
    }
    return (
      <div className="faq__form">
        <Form data={form} />
      </div>
    );
  }

  handleChange(findText) {
    const self = this;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.setState({
        showQuestions: false
      });
      setTimeout(() => {
        self.setState({
          findText,
          showQuestions: true
        });
      }, 500);
    }, 500);
  }

  handleChangeType(type) {
    const { findText } = this.state;
    const self = this;
    this.timeout = setTimeout(() => {
      this.setState({
        showQuestions: false,
        type
      });
      setTimeout(() => {
        self.setState({
          findText,
          showQuestions: true,
          oldType: type
        });
      }, 500);
    });
  }

  render() {
    const { data, intl } = this.props;
    const { questions, title, findText, generalText, technicalText } = data;
    const locale = intl.locale;
    const { type, showQuestions } = this.state;
    const filterItems = questions[locale];

    return (
      <section className="faq">
        <div className="faq__header">
          <div className="faq__wrapper">
            <div className="faq__header__title">
              <h1>{intl.formatMessage({id: title})}</h1>
            </div>
            <TextField
              id={'find'}
              leftIcon={'Search'}
              className="faq__header__input"
              placeholder={intl.formatMessage({id: findText})}
              onChange={(data) => this.handleChange(data.value)}
            />
          </div>
        </div>
        <div className="faq__wrapper">
          <div className="faq__content">
            <div className="faq__content__buttons">
              <Button
                className={cn('faq__content__btn', {
                  button__default: type === 'general',
                  button__secondary: type !== 'general',
                })}
                label={intl.formatMessage({id: generalText})}
                onClick={() => this.handleChangeType('general')}
              />
              <Button
                className={cn('faq__content__btn', {
                  button__default: type === 'technical',
                  button__secondary: type !== 'technical',
                })}
                label={intl.formatMessage({id: technicalText})}
                onClick={() => this.handleChangeType('technical')}
              />
            </div>
            <div
              className={cn({
                hidden: !showQuestions,
                show: showQuestions,
              })}
            >
              {this.renderItem(filterItems)}
            </div>
          </div>
        </div>
        <div id="hypercomments_widget" className="faq__demo__hyper" />
        {/* {this.addHypercomments()}*/}
        <div className="faq__demo">
          <div className="faq__demo__wrapper">
            <div className="faq__demo__title">
              {intl.formatMessage({id: 'faqDemoTitle'})}
            </div>
            <div className="faq__demo__buttons">
              <Link to={intl.formatMessage({id: 'faqDemoBtnCreateLink'})}>
                <Button
                  className="button__default faq__demo__btn"
                  label={intl.formatMessage({id: 'faqDemoBtnCreate'})}
                />
              </Link>
              <Link to={'/form/live-demo'}>
                <Button
                  className="button__secondary faq__demo__btn"
                  label={intl.formatMessage({id: 'faqDemoBtnDemo'})}
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FAQPage.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(FAQPage);

