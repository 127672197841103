const faq = {
  en: {
    general: [
      {
        question: 'What is the value of Corezoid for a company? Could you provide examples from real life?',
        answer: 'Corezoid allows you to move from hardcoding to process assembly. It exponentially speeds up the entire company. Corezoid makes it much easier and faster to launch new products. People not acquainted with programming languages can work with Corezoid. Companies no longer need an army of developers. Managers and business analysts can focus on the most important issue – creation of solid business processes, rather than spending time writing Technical Design Assignments, in kick-off meetings, working with the IT department and waiting for testers. For example, there were 34 developers in Internet Banking Department at PrivatBank before Corezoid implementation. Now, nine people are able to cope with this work.'
      },
      {
        question: 'Do I need to know programming languages to work with Corezoid?',
        answer: 'No, there is no need for programming languages. Logical thinking and simple skills of algorithm development would be enough.',
      },
      {
        question: 'What is Corezoid’s role in the software architecture of my company? Does it completely replace my software packages or integrate with them somehow? ',
        answer: 'Corezoid easily interacts with any software through an API. Therein lays the value of Corezoid – it is able to control the interconnection logic of multiple internal and external software systems. In tandem with the company’s development, at a certain point Corezoid can really replace other software solutions in full. For example, the availability of real-time dashboards in Corezoid provides the possibility to forgo software built for business intelligence. Accounting processes being customized in Corezoid allows to stop using outdated software in the accounting department.'
      },
      {
        question: 'Why is Corezoid better than Activiti, Jbpm, Microsoft Logic Apps, Bpmonline, IBM BPM and other BMP/ERP/CRM systems? ',
        answer: 'As all objects and their possible states are described directly in the system, Corezoid allows you to configure and manage the same processes easier and faster. Any condition instantly generates new processes in Corezoid. Processes generate new data that, in turn, generate the new state…and so on ad infinitum. BPM systems are not propelled by a state, but from processes. Firstly, a BPM system waits for a certain signal, an external impact, to start the process. Then, when the process is completed, it has the last, final result. Corezoid does not wait for external signals. When all the company processes are linked into a single ecosystem, and the objects are described in terms of finite state machines, a change in one place in the company instantly generates a series of processes. In fact, Corezoid allows you to change it. The company starts to operate as a single, coherent, living organism.'
      },
      {
        question: 'Can I use Corezoid for a highload project? ',
        answer: 'Yes. PrivatBank, one of the largest bank in Eastern Europe runs on the Corezoid process engine, with 16 million customers. About 10 million transactions are handled in Corezoid every day (300 transactions per second). As a comparison, PayPal processes 193 transactions per second.'
      },
      {
        question: 'What kind of support do you provide? ',
        answer: '24/7/365 by skype, e-mail, mobile phone, and personal manager.'
      },
      {
        question: 'Is the Corezoid environment PCI DSS compliant?',
        answer: 'We do not store any data. Corezoid works only with depersonalized information IDs. But some businesses need to have their environment PCI DSS compliant, so we have a PCI DSS certificate.'
      },
      {
        question: 'If I want to try, where should Corezoid be implemented in my company? ',
        answer: 'Visit Corezoid.com or Amazon Marketplace, log in to the system. Prepare a chart for your algorithm, for a start it can be done on paper. Connect the necessary API and configure the logic in the Corezoid interface. If required, contact us, we can help. You do not have to pay anything to try the system.'
      },
      {
        question: 'How is data loaded into Corezoid? There is no API in my company. Can I work with Corezoid? ',
        answer: 'Data enter Corezoid using the API, by downloading a text file (for example.csv) or through handloading. But the most convenient way is with an API. If you do not have your own API, we can help you to create them for your company. It takes about one month. There are free templates for Corezoid as well, e.g. to access the API Mandrill (for e-mail), Liqpay (online payments), MessageBird (SMS sending) and dozens of other templates.'
      },
      {
        question: 'Can I install Corezoid on my server instead of working in your cloud? ',
        answer: 'Yes, it is possible. Please contact us to discuss this issue on an individual basis.'
      },
      {
        question: 'What will we pay when we start working with Corezoid? Do you provide consultancy or some kind of ready process templates? ',
        answer: 'Corezoid works within the Amazon ecosystem with payment on an hourly basis. You can deploy AMI (Amazon Machine Instance) or access Corezoid in SaaS mode. You’ll be able to see Corezoid usage statistics in your account on Amazon. The invoices will also be paid via Amazon. Apart from this, you can buy a consulting project from Corezoid. We will help you to create the necessary architecture in the Corezoid.'
      },
      {
        question: 'If I moved to a new project on Corezoid, how long will it take to understand all the intricacies of the work? How much time does it take to create a process? ',
        answer: 'Simple processes can be set up in Corezoid in just one day. It takes two weeks to customize the work of the whole online store with your marketing system, logistics, sales, and accounting.'
      },
      {
        question: 'Our company develops software. Why do we need to shift processes to Corezoid, if customers pay us for software code writing and support? ',
        answer: 'You can direct your programmers to create new elegant cloud-based architectures and algorithms rather than write and support business logic. Due to Corezoid, you will manage to do much more for your clients, and earn more.'
      },
      {
        question: 'What regulates the status of copyright for products/services created on the Corezoid? I care about: – who the intellectual property rights for the created product belong to? – who the proprietary rights (possession/disposition/act of right) belong to and how they are governed? Simply: if I create a project on Corezoid, how are my rights are protected? ',
        answer: 'The relationship between the software owner and Corezoid users is governed by the user agreement: https://corezoid.com/terms-and-conditions/. These rules establish the distribution of rights and obligations in respect of Corezoid software and do not provide any special treatment for products (processes) created by users with Corezoid. In such circumstances, copyright for the processes in Corezoid belongs to the person who created them. The transfer of rights to the work created in Corezoid can be governed by an agreement between the author and the transferees. In such cases, the protection of the author’s rights shall be maintained on a general legal basis by virtue of the arrangement with the persons benefiting from the processes, or under the law. In order to protect the authors’ rights in Corezoid, operational records are maintained and we are ready to fulfil the requirements of the competent authorities to block specific users and/or processes violating the rights of the authors.'
      },
      {
        question: 'What will happen with the access to Corezoid in case of company bankruptcy or liquidation? ',
        answer: 'On client\'s demand and cost, we\'re ready to provide the option of placing Corezoid source code to the depositary suggested by the client. If something happens with our company, client will take-over the source code and run it.'
      }
    ],
    technical: [
      {
        question: 'What technologies do you use to hold data in the node? How do nodes communicate with each other? ',
        answer: 'Corezoid itself is written in Erlang. It works with the PostgreSQL database, but can do the same with Hadoop and many other databases.'
      },
      {
        question: 'Is there an option of using a version control system or something of the kind? ',
        answer: 'Each process keeps a revision history. In addition, you have access to the import/export folder/processes manually. At the earliest opportunity an integration with GIT through an API will be available.'
      },
      {
        question: 'What are the options for project deployment? Let’s take, for example, a case: An employee introduced some changes in the process and checked if everything worked. Then, he needs to give it to a team lead for review, and upon his confirmation apply the changes to the production version. How can this be done? ',
        answer: 'Now it is possible to implement by splitting the project into branches/folders: test, pre and prod. By copying the finished version of the folder with the processes from the test branch into pre, it is passed on for review. Only administrators have the ability to copy the project to prod.'
      },
      {
        question: 'How can we notify a mobile application user about the process output? ',
        answer: 'Various methods of asynchronous data delivery can be used for handheld devices. Our developers use PUSH notifications in order to "wake up" the user, and after the application is activated, it automatically requests a cached query data from the server.'
      },
      {
        question: 'Is it possible to create a universal node, where I will transmit the request type (POST, GET, PUT, etc.) and request parameters? And other nodes would be able to query the API through this node. For example, I need to check whether a phone number is used by other users. ',
        answer: 'The structure is the following:[br][ol][li]API starts the Corezoid process for Verify User. It transfers: phoneNumber, userId, access_token (to access the loopback)[/li][li]Verify User process activates the User process (model analogue), where it provides search parameters (loopback allows this), the type of request and the token.[/li][li]Process User activates API Call process where it transmits all the parameters and further indicates Restful URI[/li][li]Process API Call already refers to a given URL + transferred URI + query parameters for + access_token[/li][/ol][br]It turns out that a User “model” is used to do all queries by users, I do not need to register URL API and other data anywhere. There is a kind of data encapsulation.[br][br]Corezoid does not allow to parameterize GET/POST/PUT/… query types and shape BODY in a free form. This is done to make the processes as transparent and understandable as possible, not allowing the user to hide them somewhere in the logic of related processes.[br][br]At the same time, the user has the ability to substitute the URL parameter from the application parameters and, accordingly, to make this setting, for example, in a state diagram. In fact, the state diagram will be a config that defines the environment in which processes are executed – test or combat.[br][br]How to make a config state diagram:[br][ol][li]Create a state diagram[/li][li]Send a query through “New task” in VIEW mode REF = webapi and URL parameter[/li][li]Deduct URL from your process, for example, in the logic Set parameter in URL variable through design:[/li][/ol][br]{{conv[ID diagram].ref[webapi].URL}}'
      },
      {
        question: 'Why is the minimum value of the timer 30 seconds?',
        answer: 'This restriction was introduced in order to optimize the use of Corezoid.com resources.'
      },
      {
        question: 'How to work with exceptions, at what stage should they be processed/saved?',
        answer: 'It would be ideal to do this after the escalation node.'
      },
      {
        question: 'Is it possible to access a .csv-file on the server via GET API?',
        answer: 'Yes, it is possible, but the CSV structure will not be recognized or used.[br]If there is JSON in the file, then Corezoid will accept data.'
      },
      {
        question: 'Can data be displayed only on the dashboard embedded in Corezoid, or on an external dashboard as well?',
        answer: 'You can use Corezoid API to display statistics on your own website. Please find more info [url=https://doc.corezoid.com/docs/object-creation]here[/url]'
      },
      {
        question: 'How can your system be used for legacy solutions of the bank?',
        answer: 'This will require the separate development of a proxy-API that will transfer data to/from Corezoid.'
      },
      {
        question: 'How do I add new parameters?',
        answer: 'Use SET PARAM logic type, which allows you to add and set new parameters as well as modify the values of the existing parameters in the application.'
      },
      {
        question: 'How long is the data stored in the archive nodes?',
        answer: 'It is stored for up to three days. Applications that are more than three days old are deleted from the archive nodes. Data is never deleted from other nodes. If you need to store data in Corezoid, use the state diagram, as storing data in the process diagram is limited.'
      },
      {
        question: 'How can I add new variables via CODE logic using JavaScript?',
        answer: 'All parameters of the application used by a Corezoid-based process are kept in the “data” object.[br]Therefore, variables in [url=https://doc.corezoid.com/docs/code]CODE logic[/url] for JS should be introduced via data.variable_name = value;[br]For example:[br]data.new = “123”;[br]or[br]data.new = data.old;'
      },
      {
        question: 'Which format should be used to specify the names of variables when building a process?',
        answer: 'Variables should be enclosed in double curly braces – {{variable_name}}.'
      },
      {
        question: 'How do I create a software product or a CRM-system using visual forms?',
        answer: 'Corezoid is integrated with Sender messenger, which has its own form editor. Sender forms are created in Corezoid using Logic Form/action. The Corezoid process with such logic configured is called a Robot.'
      },
      {
        question: 'How can a Corezoid process run without using traditional databases?',
        answer: 'Corezoid has its own database – State diagram – which has no restrictions on the number of applications and allows to perform the following:[ul][li]taking into account the status of objects;[/li][li]storing data about objects in nodes;[/li][li]receiving data about objects using the CONV function;[/li][/ul][br]If necessary, you can connect any external database via API logic.'
      },
      {
        question: 'How do I integrate Corezoid with a website?',
        answer: 'Any interaction with the external services or databases occurs only via API.[br]Corezoid receives data in format JSON/XML/NPV by the HTTP POST method.[br]For data transfer, Corezoid supports external API calls in formats JSON (HTTP POST), XML (HTTP POST), GET, NVP (x-www-form-urlencoded), Corezoid and POST, GET, PUT, DELETE, HEAD, PATCH methods.'
      },
      {
        question: 'How do I receive system the parameters of an application?',
        answer: 'System parameters (process id, node id, etc.) can be selected in all logics from the drop-down list. The drop-down list is called up by double-clicking the field for entering the variable.'
      },
      {
        question: 'Is it possible to receive data from the header when calling an API?',
        answer: 'Yes. In API Logic go to ‘Show advanced’ and set a flag at ‘Customize response’.[br]For more information read [url=https://doc.corezoid.com]documentation[/url].'
      },
      {
        question: 'What if a parameter value is hidden and displayed when viewing as "***"?',
        answer: 'Parameters resembling sensitive information such as a CVV code or a card number (3 and 16 digits respectively) are hidden when viewing.[br][br]If the hidden parameter does not contain sensitive information, add the parameter to the description of the process as an output parameter (Output).'
      },
      {
        question: 'How do I withdraw applications from the node with the CALLBACK logic after a certain time?',
        answer: 'To do this, add another logic – add TIME to the node with CALLBACK and specify the condition of transition to another node in terms of time.[br]For example, in 1 hour after arriving to the node and in case of no call-back.'
      },
      {
        question: 'What does the Synchronous option in a node with Copy logic mean?',
        answer: 'It is only available for Modify and provides an opportunity to accept it for execution only in case the application is in a call-back node. Otherwise, it returns an error.'
      }
    ]
  },
  ru: {
    general: [
      {
        question: 'Какая ценность Corezoid для компании? Вы можете показать примеры из реальной жизни?',
        answer: 'Corezoid позволяет перейти от написания кода к созданию процессов. Это экспоненциально ускоряет развитие всей компании. Corezoid упрощает и ускоряет запуск новых продуктов. Компаниям больше не нужны армии разработчиков, потому что с Corezoid могут работать люди без знания языков программирования. Менеджеры и бизнес-аналитики могут сфокусироваться на важных задачах - создании новых бизнес-процессов, вместо написания ТЗ, проведения встреч, работы с департаментом ИТ и ожидания тестировщиков. Например, до Corezoid в разработке интернет-банка ПриватБанка участвовало 34 разработчика. После внедрения Corezoid всего 9 человек смогли выполнять тот же объем работы, а освободившиеся ресурсы были перераспределены для решения других банковских задач.'
      },
      {
        question: 'Надо ли мне знать языки программирования чтобы работать с Corezoid?',
        answer: 'Нет, для работы с Corezoid не нужно знание языков программирования. Необходимо только умение логически мыслить, также будет полезен навык создания алгоритмов.'
      },
      {
        question: 'Какую роль занимает Corezoid в архитектеру компании? Нужно будет заменить существующее ПО или Corezoid может интегрироваться с ним?',
        answer: 'Corezoid умеет взаимодействовать с любым ПО посредством API. В этом и заключается ценность Corezoid - в неограниченных возможностях интеграции c внешними и внутренними системами для создания различных алгоритмов. Но также Corezoid может полностью заменить существующие системы, если компания захочет перенести их функциональность в Corezoid. Например, встроенные онлайн-дашборды Corezoid позволяют заменить часть BI-инструментов. Один из банков полностью перенес свою внутреннюю систему учета на Corezoid, что позволило отказаться от устаревшего ПО.'
      },
      {
        question: 'Почему Corezoid Activiti, Jbpm, Microsoft Logic Apps, BPMonline, IBM BMP and other BPM/ERP/CRM systems?',
        answer: 'Так как все объекты и их возможные состояния описаны непосредственно в системе Corezoid, это позволяет проще и быстрее создавать и управлять процессами. Любое изменение состояния может вызывать процесс в Corezoid. Вызванный процесс генерирует новую информацию, которая изменяет состояние объекта... и так до бесконечности. Системы класса BPM оперируют лишь понятием процесс, а состояния скрыты во вшешних системах. Благодаря измненению состояний, Corezoid может самостоятельно инициировать вызов процессов. Таким образом мы можем объединить все процессы компании в единую экосистему и описать объекты в терминах конечных автоматов, когда изменение одного объекта сразу же приводит к запуску ряда процессов, которые, в свою очередь, могут изменять другие объекты. С помощью такой экосистемы компания начинает работать как единый живой организм.'
      },
      {
        question: 'Могули я использовать Corezoid для высоконагруженных проектов?',
        answer: 'Да, можете. ПриватБанк, один из крупнейших банков Восточной Европы, работает на Corezoid Process Engine, обслуживая 20 миллионов клиентов. Более 10 миллионов транзакций обрабатываются через Corezoid ежедневно (со средней нагрузкой 300 транзакций в секунду). Для сравнения, PayPal обрабатывает 193 транзакции в секунду.'
      },
      {
        question: 'Какой вид поддержки Вы оказываете?',
        answer: '24/7/365 по e-mail, сотовая связь, и персональный менеджер.'
      },
      {
        question: 'Соответствует ли Corezoid требованиям PCI DSS?',
        answer: 'Если компании необходимо окружение, которое соответствует требованиям PCI DSS - у нас есть опыт развертывания такого окружения и прохождения сертификации. Но в целом, процессы Corezoid не хранят данные. Мы всегда рекомендуем работать финансовым компаниям с анонимизированными ID.'
      },
      {
        question: 'Если я хочу попробовать Corezoid, нужно ли мне разворачивать его на серверах компании?',
        answer: 'Вы можете попробовать Corezoid на нашем сайте Corezoid.com или на Amazon Marketplace. Оба эти варианта хостинга предусматривают наличие бесплатной 30-дневной пробной версии.'
      },
      {
        question: 'Как мне загрузить данные в Corezoid, если у моей компании нет API?',
        answer: 'Данные в Corezoid могут быть загружены 3 способами: по API, через файл в формате .csv и ручной ввод. Но самый удобый способ - это API. Если у вас нет API, мы можем помочь вашей компании создать их. Обычно это занимает около месяца.'
      },
      {
        question: 'Могу ли я установить Corezoid на сервера компании?',
        answer: 'Да, можете. Пожалуйста, свяжитесь с нам чтобы обсудить условия создания инсталляции Corezoid на ваши сервера.'
      },
      {
        question: 'Сколько стоит Corezoid? Есть ли у Вас обучение, поддержка или готовые шаблоны процессов?',
        answer: 'Платное использование Corezoid начинается от $30/месяц в облаке Corezoid.com. Вы можете установить Corezoid на ваши сервера в AWS. Вы сможете увидеть статистику использования Corezoid в аккаунте AWS. Все счета будут оплачены через AWS. Помимо этого, Вы можете купить обучение ваших сотрудников работе с Corezoid, пакет поддержки, а также взять готовые процессы с Corezoid Marketplace. Свяжитесь с нами для обсуждения условий использования Corezoid.'
      },
      {
        question: 'Если я начну новый проект в Corezoid, сколько времени займет изучение инструмента?',
        answer: 'Научиться строить простые процессы вы можете всего за 1 день. В течение 2 недель вы можете научиться строить сложные процессы, и успеть автоматизировать маркетинг, логистику, продажи и учет небольшого интернет-магазина.'
      },
      {
        question: 'Наша компания занимается разработкой ПО. Зачем нам переходить на Corezoid, если клиенты нам платят за написание кода и его поддержку?',
        answer: 'Вы можете перевести ваших разработчиком на создание новых элегантных облачных архитектур и алгоритмов, вместо написания и поддержки бизнес-логики. Благодаря Corezoid, вы сможете делать больше задач для ваших клиентов и, соответственно, больше зарабатывать.'
      },
      {
        question: 'Какими документами/соглашениями регламентируются права собственности, возникающие при создании продуктов/сервисов в Corezoid?',
        answer: 'Отношения между владельцем программного обеспечения и пользователями Corezoid регулируются пользовательским соглашением: https://corezoid.com/terms-and-conditions/. Эти правила устанавливают распределение прав и обязанностей в отношении программного обеспечения Corezoid и не предусматривают какой-либо особый режим для продуктов (процессов), созданных пользователями в Corezoid. В таких обстоятельствах авторские права на процессы в Corezoid принадлежат тому, кто их создал. Передача прав на произведение, созданное в Corezoid, может регулироваться соглашением между автором и получателями. В таких случаях защита прав автора должна поддерживаться на общеправовой основе в силу договоренности с лицами, участвующими в процессе, или в соответствии с законом. В целях защиты прав авторов в Corezoid ведутся оперативные записи, и мы готовы выполнить требования компетентных органов по блокированию конкретных пользователей и / или процессов, нарушающих права авторов.'
      },
      {
        question: 'Что будет с доступом к Corezoid в случае банкротства или ликвидации компании?',
        answer: 'По требованию и за счет клиента мы готовы предоставить возможность размещения исходного кода Corezoid в депозитарии, предложенном клиентом. Если что-то случится с нашей компанией, клиент получит исходный код и запустит его'
      }
    ],
    technical: [
      {
        question: 'Какие технологии вы используете для хранения данных в узле? Как узлы общаются друг с другом?',
        answer: 'Сам Corezoid написан на Erlang. "Под капотом" находится база данных PostgreSQL, но по вашему запросу это может быть Hadoop или другие базы данных.'
      },
      {
        question: 'Есть ли возможность использовать систему контроля версий или что-то в этом роде?',
        answer: 'Каждый процесс содержит историю изменений. Кроме того, вы можете вручную загружать и выгружать любые папки и процессы. В скором времени будет добавлена интеграция с GIT через API.'
      },
      {
        question: 'Какие есть возможности для управления версиями проектов? Возьмем, к примеру, случай: сотрудник внес некоторые изменения в процесс и проверил, все ли работает. Затем он должен передать процесс своего руководителю для проверки работоспособности и, после утверждения, применить изменения к рабочей версии. Как это может быть сделано?',
        answer: 'Сейчас это можно реализовать, разбив проект на ветки / папки: test, pre и prod. Скопировав готовую версию папки с процессами из тестовой ветки в pre, она передается на проверку. Только администраторы (руководители) будут иметь возможность скопировать проект в "боевую" среду.'
      },
      {
        question: 'Как мы можем уведомить пользователя мобильного приложения о результате выполнения процесса?',
        answer: 'Различные методы асинхронной доставки данных могут быть использованы для мобильных устройств. Наши разработчики используют PUSH-уведомления для того, чтобы «разбудить» пользователя, и после активации приложения оно автоматически запрашивает актуальные данные с сервера.'
      },
      {
        question: 'Почему минимальное значение таймера 30 секунд?',
        answer: 'Это ограничение было введено с целью оптимизации использования ресурсов Corezoid.com.'
      },
      {
        question: 'Как работать с исключениями, на каком этапе их следует обрабатывать / сохранять?',
        answer: 'Мы рекомендуем отправлять эскалации владельцу процесса в бота / через SMS или Email во всех отклонения процесса от прогнозируемого поведения.'
      },
      {
        question: 'Можно ли получить доступ к .csv-файлу на сервере через GET API?',
        answer: 'Да, это возможно, но структура CSV не будет распознана или использована.[br]Если в файле есть JSON, то Corezoid будет сможет принять и обработать данные.'
      },
      {
        question: 'Могу ли я просматривать статистику использования процессов Corezoid во внешних дашбордах, а не только с помощью встроенных в Corezoid?',
        answer: 'Вы можете использовать Corezoid API для получения статистики процессов. Больше информации вы можете найти [url=https://doc.corezoid.com/docs/object-creation]здесь[/url].'
      },
      {
        question: 'Как Corezoid может быть использован вместе с устаревшим банквоским ПО?',
        answer: 'Это потребует отдельной разработки прокси-API, который будет передавать данные в / из Corezoid.'
      },
      {
        question: 'Как мне добавить новые параметры в процесс?',
        answer: 'Используйте узел "Set Parameter", который позволяет добавлять и задавать новые параметры, а также изменять значения существующих параметров процесса.'
      },
      {
        question: 'Как долго данные хранятся в архивных узлах?',
        answer: 'Данные хранятся до трех дней. Вся информация старше трех дней удаляются из архивных узлов. В остальных узлах данные хранятся безлимитное кол-во времени. Если вам нужно хранить данные в Corezoid, используйте диаграмму состояний, поскольку хранение данных в диаграмме процесса ограничено.'
      },
      {
        question: 'Какой формат следует использовать для указания имен переменных при построении процесса?',
        answer: 'Переменные должны быть заключены в двойные фигурные скобки - {{имя_переменной}}.'
      },
      {
        question: 'Как создать программный продукт или CRM-систему с использованием визуальных форм?',
        answer: 'Corezoid нативно интегрирован с мессенджером Sender, который имеет собственный редактор форм. Формы Sender создаются в Corezoid с помощью drag&drop-редактора.'
      },
      {
        question: 'Как можно запустить процесс Corezoid без использования традиционных баз данных?',
        answer: 'Corezoid имеет собственную базу данных - диаграмму состояний - которая не имеет ограничений по времени хранения данных и позволяет выполнять следующие операции: учет состояний объектов; хранение данных об объектах в узлах; получение данных об объектах. При необходимости вы можете подключить любую внешнюю базу данных через логику API.'
      },
      {
        question: 'Как интегрировать Corezoid с веб-сайтом?',
        answer: 'Любое взаимодействие с внешними службами или базами данных происходит только через API. [Br] Corezoid получает данные в формате JSON / XML / NPV методом HTTP POST.[br]Для передачи данных Corezoid поддерживает вызовы внешнего API в форматах JSON (HTTP POST), XML (HTTP POST), GET, NVP (x-www-form-urlencoded), Corezoid и методы POST, GET, PUT, DELETE, HEAD, PATCH.'
      },
      {
        question: 'Можно ли получить данные из заголовка при вызове API?',
        answer: 'Да. В узле API Call перейдите в раздел "Additionally" и активируйте строку "Customize response parameters". Для получения дополнительной информации о работе с заголовками рекомендуем ознакомиться с [url=https://doc.corezoid.com/v2/docs]документацией Corezoid[/url] ,'
      },
      {
        question: 'Что делать, если значение параметра скрыто и отображается при просмотре как «***»?',
        answer: 'Параметры, напоминающие конфиденциальную информацию, такую как код CVV или номер карты (3 и 16 цифр соответственно), автоматически скрываются при просмотре данных заявки.[br][br]Если скрытый параметр не содержит конфиденциальную информацию, добавьте параметр в меню "Task parameters" в качестве входящего параметра (Input) и поставьте галочку "Auto-clear".'
      }
    ]
  }
};

export default faq;
